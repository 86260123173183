.note-edit-group {
  padding: 8px 8px;
  margin: 4px 0;
}

.note-edit-group-header {
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.note-edit-value-label, .note-edit-value-label + .MuiFormControl-root {
  padding-left: 4px;
}

.note-edit-value-label {
  text-transform: lowercase;
  font-weight: 500;
  margin-bottom: 2px;
}

.note-edit-value-label + .MuiFormControl-root {
  margin-bottom: 6px;
}