
.filter-group {
  padding-top: 4px;
}

.filter-group-label {
  padding-top: 4px;
  font-size: 1rem;
  font-weight: 500;
}

.filter-item-label {
  font-size: .85rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
