.virtuoso-grid-item-container {
  width: 50%;
  display: flex;
  flex-shrink: 0;
}

.virtuoso-grid-list-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 40px;
  border-radius: .25rem;
}