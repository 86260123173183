body, html {
  height: 100%;
  overflow: hidden;
}

body *::selection {
  background-color: var(--color-primary-light);
  color: black;
}

.wpc-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.wpc-content {
  flex: 1 1;
  overflow: auto;
}

.wpc-no-pointer-events {
  pointer-events: none !important;
}

.wpc-disabled {
  background-color: var(--color-disabled) !important;
  border-color: var(--color-disabled) !important;
  pointer-events: none !important;
  color: var(--color-disabled-text) !important;
}

.wpc-outline.wpc-disabled {
  border-color: var(--color-disabled) !important;
  background-color: transparent !important;
  pointer-events: none !important;
  color: var(--color-disabled-text) !important;
}

.wpc-no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wpc-no-select, .wpc-no-outline {
  outline: none !important;
}

.wpc-no-outline:focus-visible {
  outline: none !important
}

.wpc-mention {
  font-weight: 700;
}

.wpc-notification-title {
  font-weight: 700;
}

.wpc-user-avatar-background:before {
  content: "";
  border-radius: 50%;
  background: var(--color-surface);
  border: 4px solid var(--color-surface);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.MuiAutocomplete-noOptions, .MuiAutocomplete-loading, .MuiAutocomplete-listbox#category-search-listbox {
  background-color: var(--color-s04dp) !important;
}