.truncate-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.line-clamp-1, .line-clamp-2, .line-clamp-3 {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.wrap-long-lines {
  overflow-wrap: anywhere;
}